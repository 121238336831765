import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Tout = makeShortcode("Tout");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <StatusBanner warning mdxType="StatusBanner">
      <p><strong parentName="p">{`The grants program is not accepting new applications at the moment. Please check back later for the next wave.`}</strong></p>
    </StatusBanner>
    <h1>{`Development Grants`}</h1>
    <p>{`The MakerDAO Community grants program funds projects that increase Dai adoption, support emerging economies, open new markets, provide novel uses for the stablecoin, or other further the MakerDAO Foundation's principles.`}</p>
    <p>{`We allocate grants ranging from 5K - 50K Dai. We distribute blocks of funds as teams reach their predetermined milestones.`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h3>{`What We Fund`}</h3>
        <ul>
          <li parentName="ul">{`Tools That Directly Benefit Dai and MKR Users`}</li>
          <li parentName="ul">{`Expanding Dai Adoption`}</li>
          <li parentName="ul">{`Increasing Access to Personal Liquidity`}</li>
        </ul>
      </Box>
      <Box mdxType="Box">
        <h3>{`What We Don't Fund`}</h3>
        <ul>
          <li parentName="ul">{`Pure R&D`}</li>
          <li parentName="ul">{`Information Gathering`}</li>
          <li parentName="ul">{`Additional Runway`}</li>
          <li parentName="ul">{`Generic Ethereum Ecosystem Improvements`}</li>
          <li parentName="ul">{`Large Marketing Budgets`}</li>
          <li parentName="ul">{`Dai Airdrops | Giveaways`}</li>
        </ul>
      </Box>
    </Tout>
    <Aligner center mdxType="Aligner">
      <Button to="/funding/development-grants/development-grants-info/" mdxType="Button">Apply for a Development Grant</Button>
    </Aligner>
    <Aligner center mdxType="Aligner">
      <Button to="/funding/development-grants/grantee-playbook/" mdxType="Button">Read the Grantee Playbook</Button>
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      